import { useState, useEffect } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import { Alert, Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { getProduct, editProduct, deleteProduct } from "services/products"
import { Loading } from "components"
import { ProductsForm } from "components/products"
import storage from "services/storage"
import { authUserKey } from "config/API"
import ProductsPresentation from "./ProductPresentation"
import { getAllPrices } from "services/prices"

const ProductRetrive = () => {
  document.title = "Producto | Punto de venta"

  const [successAlert, setSuccessAlert] = useState(false)
  const [state, setState] = useState({ activeTab: "product" })

  const { id } = useParams()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const history = useHistory()

  const { data, error, isLoading, isError, refetch } = useQuery(["product", id], () => getProduct(id))
  const prices = useQuery(["prices"], () => getAllPrices())
  const productDeleted = useMutation((id) => deleteProduct(id))

  const permissions = storage.get(authUserKey).user_permissions || []

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["product", id], (data) => editProduct(id, data))

  useEffect(() => {
    if (isSuccessEdit) {
      refetch()
      setSuccessAlert(true)
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (successAlert) {
      Toast.fire({
        icon: "success",
        title: "Producto modificado satisfactoriamente",
        didOpen: () => {
          refetch()
          setSuccessAlert(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [successAlert])

  const toggleActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      })
    }
  }

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al producto: <b>${row.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        productDeleted.mutateAsync(row?.id).then(() => {
          Toast.fire("!Eliminado!", `El producto <b>${row.name}</b> ha sido eliminado correctamente`, "success").then(
            () => {
              history.push("/products")
            }
          )
        })
      }
    })
  }

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.prices = dataTemp.prices
      ? dataTemp.prices?.map((price) => ({
          id: price.id,
          price: price.price.id,
          amount: price.amount,
          quantity: price.quantity,
          utility: price.utility,
        }))
      : null
    dataTemp.prices = dataTemp.prices.filter((price) => price.utility !== "0")
    // (price.amount === 0 || price.utility === 0)
    dataTemp.providers = dataTemp.providers ? dataTemp.providers?.map((provider) => provider.id) : undefined
    dataTemp.brand = dataTemp?.brand?.value
    dataTemp.unit_measurement = dataTemp?.unit_measurement?.value || null
    dataTemp.taxes = dataTemp.taxes ? dataTemp.taxes?.map((tax) => tax.id) : null
    await mutateAsync(dataTemp)
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_product") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/products" className="btn btn-primary" style={{ marginRight: "10px" }}>
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1>Producto: {data?.name}</h1>
            </div>
          </Col>
        </Row>
        <div className="bg-white p-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${state.activeTab === "product" && "active"}`}
                onClick={() => toggleActiveTab("product")}
              >
                Producto
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${state.activeTab === "productPresentation" && "active"}`}
                onClick={() => toggleActiveTab("productPresentation")}
              >
                Presentaciones de Producto
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="product">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              {isError ? (
                isErrorDiv()
              ) : isLoading ? (
                <div className="text-center">
                  <Loading isLoading={isLoading} size={2} />
                </div>
              ) : (
                <>
                  <Row>
                    <Col className="text-end">
                      {permissions.some((e) => e.codename === "delete_product") && (
                        <Button color="danger" onClick={() => openDeleteModal(data)} disabled={isLoading}>
                          <i className="fa fa-trash" /> Eliminar
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ProductsForm
                        isRetrivePage={true}
                        submit={submit}
                        data={data}
                        isLoading={isLoadingEdit}
                        isError={isErrorEdit}
                        error={errorEdit}
                        refetchData={refetch}
                        prices={prices}
                        disabledForm={!permissions.some((e) => e.codename === "change_product")}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </TabPane>
            <TabPane tabId="productPresentation">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              <ProductsPresentation />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default ProductRetrive
