import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Alert, Button, Container, Col, Row } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { ImageList, ImageListItem, ImageListItemBar, IconButton } from "@mui/material"
import StarBorderIcon from "@mui/icons-material/StarBorder"

import storage from "services/storage"
import { API, authUserKey } from "config/API"
import { getBusinessInfo } from "services/business-info"

const BusinessInfo = () => {
  document.title = "Información del Ticket | Punto de venta"

  const Location = useLocation()
  const history = useHistory()
  const [filters, setFilters] = useState("")
  const [image, setImage] = useState("")
  const businessInfo = useQuery(["businessInfo", filters, filters.toString()], () => getBusinessInfo(filters))
  const business = businessInfo?.data?.results[0]
  const apiUrl = ["https://api.pos.emkode.org", "https://api-dulceria.emkode.com", "https://api-plasticos.emkode.com"]

  const permissions = storage.get(authUserKey).user_permissions || []
  const canEdit = permissions.some((e) => e.codename === "change_businessinfo")

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  const handleOnClick = () => {
    history.push(`/business-info/${business?.id}`)
  }

  useEffect(() => {
    if (apiUrl.includes(API.defaults.baseURL)) {
      const imageArray = business?.image?.medium_thumbnail?.split("media")
      if (imageArray){
        const imageString = imageArray[0] + ".media" + imageArray[1]
        setImage(imageString)
      } else {
        setImage(business?.image?.medium_thumbnail)
      }
    } else {
      setImage(business?.image?.medium_thumbnail)
    }
  }, [business])

  return !permissions.some((e) => e.codename === "view_businessinfo") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Alert color="danger" isOpen={businessInfo.isError}>
            {businessInfo?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2 style={{ textAlign: "center" }}>Información del Ticket</h2>
          <div className="bg-white p-3">
            <div className="image">
              <Row>
                <Col xs="auto">
                  <ImageList
                    sx={{
                      width: 280,
                      height: 280,
                      transform: "translateZ(0)",
                    }}
                    rowHeight={1}
                    gap={1}
                  >
                    <ImageListItem key={1} cols={1} rows={1} />
                    <ImageListItem key={business?.id} cols={1} rows={1} />
                    <img
                      src={`${image}?w=248&fit=crop&auto=format`}
                      srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt="Logo de la empresa"
                      loading="lazy"
                    />
                    <ImageListItemBar
                      sx={{
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                          "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                      }}
                      title="Logo de la empresa"
                      position="top"
                      actionIcon={
                        <IconButton sx={{ color: "white" }} aria-label={"star logo de la empresa"}>
                          <StarBorderIcon />
                        </IconButton>
                      }
                      actionPosition="left"
                    />
                    <ImageListItem />
                  </ImageList>
                </Col>
                <Col xs="auto">
                  <h4>
                    <b>Nombre:</b> {business?.name}
                  </h4>
                  <h4>
                    <b>Dirección:</b> {business?.street} {business?.neighborhood}
                  </h4>
                  <h4>
                    <b>Código postal:</b> {business?.zip_code}
                  </h4>
                  <h4>
                    <b>Número telefónico:</b> {business?.phone_number}
                  </h4>
                  <h4>
                    <b>RFC:</b> {business?.rfc}
                  </h4>
                  <h4>
                    <b>Razón social:</b> {business?.business_name}
                  </h4>
                  <h4>
                    <b>Dirección fiscal:</b> {business?.fiscal_address}
                  </h4>
                  <h4>
                    <b>Código postal fiscal:</b> {business?.fiscal_zipcode}
                  </h4>
                </Col>
                {canEdit && (
                  <Col className="justify-content-end" style={{ display: "flex", alignItems: "flex-end" }}>
                    <Button
                      style={{ alignContent: "flex-end", float: "right" }}
                      onClick={handleOnClick}
                      color="info"
                      type="submit"
                    >
                      <i className="fa fa-edit" /> Editar
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BusinessInfo
