import React, { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery } from "@tanstack/react-query"
import Select from "react-select"
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
  InputGroup,
  InputGroupText,
  Tooltip,
} from "reactstrap"
import Swal from "sweetalert2"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllProviders } from "services/providers"
import { CreateModalPrices, ToolTipPrices } from "./ModalPrices"

import schema from "./schema"
import { getAllBrands } from "services/brands"
import { getAllMeasurements } from "services/unit-measurement"
import { getAllTaxes } from "services/taxes"

const ProductsForm = (props) => {
  const {
    data,
    disabledForm,
    error,
    isError,
    isLoading: externIsLoading,
    isRetrivePage,
    refetchData,
    submit,
    toggle,
    prices,
  } = props
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [filters, setfilters] = useState("")
  const [inputSelect, setInputSelect] = useState("")
  // eslint-disable-next-line
  const [errorPrices, setErrorPrices] = useState({})
  // eslint-disable-next-line
  const [isErrorPrices, setIsErrorPrices] = useState(false)
  const [modalPricesCreate, setModalPricesCreate] = useState(false)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const [tooltipOpenQuantity, setTooltipOpenQuantity] = useState(false)
  const toggleTooltipQuantity = () => setTooltipOpenQuantity(!tooltipOpenQuantity)

  const [tooltipOpenQuantityDisabled, setTooltipOpenQuantityDisabled] = useState(false)
  const toggleTooltipQuantityDisabled = () => setTooltipOpenQuantityDisabled(!tooltipOpenQuantityDisabled)

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const providers = useQuery(["providers"], () => getAllProviders(filters))
  const brands = useQuery(["brands"], () => getAllBrands())
  const unitMeasurements = useQuery(["unit_measurement"], () => getAllMeasurements())
  const taxes = useQuery(["taxes"], () => getAllTaxes())
  const activePrices = prices?.data?.filter((price) => price.is_active)
  const updatedData = addMissingPrices(activePrices, sortedData(data?.prices))
  const toggleModalPricesCreate = () => {
    setModalPricesCreate(!modalPricesCreate)
  }
  let orderPrices
  if (data) {
    // eslint-disable-next-line
    orderPrices = sortedData(data?.prices)
  }

  function currencyFormatter({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function sortedData(data) {
    const sorted = data?.slice().sort((a, b) => {
      const nameA = a.price.name
      const nameB = b.price.name

      if (nameA === "Sugerido") return 1
      if (nameB === "Sugerido") return -1

      const numA = parseInt(nameA.replace("P", ""))
      const numB = parseInt(nameB.replace("P", ""))

      return numA - numB
    })
    return sorted
  }

  function addMissingPrices(activePrices, data) {
    const sorted = data
    const existingPriceNames = new Set(sorted?.map((item) => item.price.name))

    for (const key in activePrices) {
      const activePrice = activePrices[key]
      const activePriceName = activePrice.name
      if (!existingPriceNames.has(activePriceName)) {
        sorted?.push({
          price: {
            id: activePrice.id,
            name: activePrice.name,
            description: activePrice.description,
            is_active: activePrice.is_active,
          },
          amount: "0",
          quantity: 0,
          utility: "0",
        })
      }
    }
    const sortedFinal = sortedData(sorted)
    return sortedFinal
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        {providers.isError && (
          <Alert color="danger" isOpen={providers.isError}>
            {providers?.error?.errors?.map((error) => error?.message)}
          </Alert>
        )}
        {isErrorPrices && (
          <Alert color="danger" isOpen={isErrorPrices}>
            {errorPrices?.errors?.map((error) => error?.message)}
          </Alert>
        )}
      </div>
    )
  }

  const isLoading = providers.isLoading || externIsLoading

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          refetchData()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams("")
      params.set("search", inputSelect)
      setfilters(params)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputSelect])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  const renderListProductItem = (price, index, errors, touched, setFieldValue, values, handleBlur) => {
    if (!price?.price?.is_active) {
      return null
    }
    return (
      <tr key={index}>
        <ToolTipPrices
          index={index}
          price={price}
          name={price?.price?.identifier}
          description={price?.price?.description}
        />
        <td>
          <InputGroup>
            <InputGroupText>$</InputGroupText>
            <Input
              autoComplete="off"
              name={`${price?.price?.name}`}
              className={
                touched?.price && touched?.price?.amount && errors.price && errors?.price?.amount
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onBlur={(e) => {
                const formattedValue = currencyFormatter({ value: e.target.value })
                // Actualizar el valor formateado en el estado del formulario
                setFieldValue(`prices.[${index}].amount`, formattedValue)
                handleBlur(e)
              }}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/,/g, "") // Eliminar todas las comas
                const parsedValue = parseFloat(inputValue) // Parsear a punto flotante
                const newPrices = values?.prices
                const cost = parseFloat(values.cost.replace(/,/g, ""))
                if (!isNaN(parsedValue)) {
                  const newUtility = (parsedValue / cost) * 100 - 100
                  newPrices[index].amount = parsedValue
                  newPrices[index].utility = newUtility.toFixed(2)
                } else {
                  newPrices[index].amount = cost
                  newPrices[index].utility = 0
                }
                setFieldValue("prices", newPrices)
              }}
              value={values.prices[index]?.amount}
              required={index === 0 && true}
            />
          </InputGroup>
          {touched?.price && touched?.price?.amount && errors?.price && errors?.price?.amount && (
            <FormFeedback type="invalid">{errors?.price?.amount}</FormFeedback>
          )}
        </td>
        <td>
          <InputGroup>
            <Input
              autoComplete="off"
              name={`${price?.utility}`}
              type="number"
              className={
                touched?.price && touched?.price?.utility && errors.price && errors?.price?.utility
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onBlur={(e) => {
                const parsedValue = e.target.value
                const cost = parseFloat(values.cost.replace(/,/g, ""))
                if (parsedValue < 0 && parseFloat(parsedValue) !== -100) {
                  setFieldValue(`prices.[${index}].amount`, cost)
                  setFieldValue(`prices.[${index}].utility`, 0)
                  return
                }
                const formattedValue = currencyFormatter({ value: values.prices[index].amount })

                // Actualizar el valor formateado en el estado del formulario
                setFieldValue(`prices.[${index}].amount`, formattedValue)
                handleBlur(e)
              }}
              onChange={(e) => {
                const parsedValue = e.target.value
                const cost = parseFloat(values.cost.replace(/,/g, ""))
                let amount = (cost * (parseFloat(parsedValue) + 100)) / 100
                amount = parseFloat(amount.toFixed(2))
                if (!isNaN(parsedValue)) {
                  setFieldValue(`prices.[${index}].price.id`, price.price.id)
                  setFieldValue(`prices.[${index}].amount`, amount)
                  setFieldValue(`prices.[${index}].utility`, parsedValue)
                } else {
                  setFieldValue(`prices.[${index}].amount`, 0)
                  setFieldValue(`prices.[${index}].utility`, 0)
                }
              }}
              value={values.prices[index]?.utility}
              required={index === 0 && true}
            />
            <InputGroupText>%</InputGroupText>
          </InputGroup>
          {touched?.price && touched?.price?.utility && errors?.price && errors?.price?.utility && (
            <FormFeedback type="invalid">{errors?.price?.utility}</FormFeedback>
          )}
        </td>
        <td>
          <InputGroup>
            <InputGroupText>{">="}</InputGroupText>
            {price?.price?.discount_volume || price?.price?.discount_volume === undefined  ? (
              <Input
                autoComplete="off"
                name={price?.quantity}
                className={
                  touched?.price && touched?.price?.quantity && errors.price && errors?.price?.quantity
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onBlur={handleBlur}
                onChange={(e) => {
                  const value = e.target.value

                  if (!isNaN(value)) {
                    setFieldValue(`prices.[${index}].quantity`, value)
                  }
                }}
                value={index === 0 ? 1 : values.prices[index]?.quantity}
                required={index === 0 && true}
                disabled={index === 0 && true}
              />
            ) : (
              <>
                <Input id={"quantityDisable"} disabled={true} type="text" value={"Precio para clientes asignados"} />
                <Tooltip
                  hideArrow={true}
                  innerClassName="mb-1"
                  style={{ backgroundColor: "#74788d" }}
                  placement={"top"}
                  isOpen={tooltipOpenQuantityDisabled}
                  target={"quantityDisable"}
                  toggle={toggleTooltipQuantityDisabled}
                >
                  <span>Regla de descuento por volumen desactivada en la configuración de precios</span>
                </Tooltip>
              </>
            )}
          </InputGroup>
          {touched?.price && touched?.price?.quantity && errors?.price && errors?.price?.quantity && (
            <FormFeedback type="invalid">{errors?.price?.quantity}</FormFeedback>
          )}
        </td>
      </tr>
    )
  }

  const renderListProductItemCreate = (price, index, errors, touched, setFieldValue, values, handleBlur) => {
    if (!price?.is_active) {
      return null // Si el precio no está activo, no generamos la fila
    }
    return (
      <tr key={price?.id}>
        <ToolTipPrices index={index} name={price?.identifier} description={price?.description} />
        <td>
          <InputGroup>
            <InputGroupText>$</InputGroupText>
            <Input
              autoComplete="off"
              className={
                touched?.price && touched?.price?.amount && errors.price && errors?.price?.amount
                  ? "form-control is-invalid"
                  : "form-control"
              }
              name={`${values?.prices?.name}`}
              onBlur={(e) => {
                const formattedValue = currencyFormatter({ value: e.target.value })

                // Actualizar el valor formateado en el estado del formulario
                setFieldValue(`prices.[${index}].amount`, formattedValue)
                handleBlur(e)
              }}
              onChange={(e) => {
                let utility = (e.target.value / values.cost) * 100 - 100
                utility = parseFloat(utility.toFixed(2))

                if (!isNaN(utility)) {
                  setFieldValue(`prices.[${index}].price`, price.id)
                  setFieldValue(`prices.[${index}].utility`, utility)
                  setFieldValue(`prices.[${index}].amount`, e.target.value)
                } else {
                  setFieldValue(`prices.[${index}].amount`, 0)
                  setFieldValue(`prices.[${index}].utility`, 0)
                }
              }}
              value={values?.prices[index]?.amount || ""}
              required={index === 0 && true}
            />
          </InputGroup>
          {touched?.price && touched?.price?.amount && errors?.price && errors?.price?.amount && (
            <FormFeedback type="invalid">{errors?.price?.amount}</FormFeedback>
          )}
        </td>
        <td>
          <InputGroup>
            <Input
              autoComplete="off"
              className={
                touched?.price && touched?.price?.utility && errors.price && errors?.price?.utility
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="number"
              name={`${values?.prices?.utility}`}
              onBlur={(e) => {
                const parsedValue = e.target.value
                const cost = parseFloat(values.cost.replace(/,/g, ""))
                if (parsedValue < 0 && parseFloat(parsedValue) !== -100) {
                  setFieldValue(`prices.[${index}].amount`, cost)
                  setFieldValue(`prices.[${index}].utility`, 0)
                  return
                }
                const amount = parseFloat(values.prices[index].amount.replace(/,/g, ""))
                const formattedValue = currencyFormatter({ value: amount })

                // Actualizar el valor formateado en el estado del formulario
                setFieldValue(`prices.[${index}].amount`, formattedValue)
                handleBlur(e)
              }}
              onChange={(e) => {
                let amount = (values.cost * (parseFloat(e.target.value) + 100)) / 100
                amount = amount.toFixed(2)
                if (!isNaN(e.target.value)) {
                  setFieldValue(`prices.[${index}].price`, price.id)
                  setFieldValue(`prices.[${index}].amount`, amount)
                  setFieldValue(`prices.[${index}].utility`, e.target.value)
                } else {
                  setFieldValue(`prices.[${index}].amount`, 0)
                  setFieldValue(`prices.[${index}].utility`, 0)
                }
              }}
              value={values?.prices[index]?.utility ?? ""}
              required={index === 0 && true}
            />
            <InputGroupText>%</InputGroupText>
          </InputGroup>
          {touched?.price && touched?.price?.utility && errors?.price && errors?.price?.utility && (
            <FormFeedback type="invalid">{errors?.price?.utility}</FormFeedback>
          )}
        </td>
        <td>
          <InputGroup>
            <InputGroupText>{">="}</InputGroupText>
            {price?.discount_volume ? (
              <Input
                autoComplete="off"
                className={
                  touched?.price && touched?.price?.quantity && errors.price && errors?.price?.quantity
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name={`${values?.prices?.quantity}`}
                onBlur={handleBlur}
                onChange={(e) => {
                  const value = e.target.value // Convert input value to a floating-point number

                  if (!isNaN(value)) {
                    setFieldValue(`prices.[${index}].quantity`, value)
                  }
                }}
                value={index === 0 ? "1" : values.prices[index]?.quantity}
                required={index === 0 && true}
                disabled={index === 0 && true}
              />
            ) : (
              <>
                <Input id={"quantityDisable"} disabled={true} type="text" value={"Precio para clientes asignados"} />
                <Tooltip
                  hideArrow={true}
                  innerClassName="mb-1"
                  style={{ backgroundColor: "#74788d" }}
                  placement={"top"}
                  isOpen={tooltipOpenQuantityDisabled}
                  target={"quantityDisable"}
                  toggle={toggleTooltipQuantityDisabled}
                >
                  <span>Regla de descuento por volumen desactivada en la configuración de precios</span>
                </Tooltip>
              </>
            )}
          </InputGroup>
          {touched?.price && touched?.price?.quantity && errors?.price && errors?.price?.quantity && (
            <FormFeedback type="invalid">{errors?.price?.quantity}</FormFeedback>
          )}
        </td>
      </tr>
    )
  }

  return (
    <>
      {providers.error ? (
        isErrorDiv()
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            name: data?.name ?? "",
            providers:
              data?.providers?.map((provider) => ({
                value: provider.id,
                id: provider.id,
                label: provider.name ?? provider.label,
              })) ?? [],
            bar_code: data?.bar_code ?? "",
            sat_code: data?.sat_code ?? "",
            cost: data?.cost ?? "",
            brand: data?.brand ? { value: data?.brand?.id, label: data?.brand?.name } : undefined,
            prices: data?.id ? updatedData : [],
            unit_measurement: data?.unit_measurement
              ? { value: data?.unit_measurement?.id, label: data?.unit_measurement?.name }
              : undefined,
            taxes: data?.taxes?.map((tax) => ({ value: tax.id, id: tax.id, label: tax.key ?? tax.name })) ?? [],
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>
              {useEffect(() => {
                // eslint-disable-next-line array-callback-return
                values.prices.map((_, index) => {
                  const cost = parseFloat(values.cost.replace(/,/g, ""))
                  const oldAmount = parseFloat(values.prices[index].amount) * 100
                  const newUtility = oldAmount / cost - 100
                  const formattedValue = currencyFormatter({ value: newUtility })
                  setFieldValue(`prices.[${index}].utility`, formattedValue)
                })
              }, [values.cost])}
              {data?.id && (
                <>
                  {!disabledForm && (
                    <CreateModalPrices
                      isOpen={modalPricesCreate}
                      toggle={toggleModalPricesCreate}
                      registeredPrices={data?.prices}
                      product={data}
                      productId={data?.id}
                      setShowAlertSuccess={setShowAlertSuccess}
                      setMsgSuccess={setMsgSuccess}
                    />
                  )}
                </>
              )}
              <Row className="mb-3">
                <Col sm={6}>
                  <Label className="form-label">
                    Nombre <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    disabled={disabledForm}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    invalid={
                      !!(touched.name && errors.name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="name"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="brand">Marca</Label>
                  <Select
                    name="brand"
                    id="brand"
                    className={
                      (touched.brand && errors.brand) ||
                      (isError && validationErrors.findIndex((e) => e.field === "brand") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.brand}
                    isDisabled={disabledForm}
                    onChange={(val) => {
                      setFieldValue("brand", val)
                    }}
                    onBlur={handleBlur}
                    onInputChange={setInputSelect}
                    isLoading={isLoading}
                    options={brands?.data?.map((row) => ({ ...row, label: row.name, value: row.id }))}
                    noOptionsMessage={() => "0 marcas"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Marca"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="brand"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={6}>
                  <Label className="form-label">
                    Código de barras <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    disabled={disabledForm}
                    name="bar_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.bar_code}
                    invalid={
                      !!(touched.bar_code && errors.bar_code) ||
                      (isError && validationErrors.findIndex((e) => e.field === "bar_code") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="bar_code"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <Label className="form-label">
                    Código de SAT <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    disabled={disabledForm}
                    name="sat_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.sat_code}
                    invalid={
                      !!(touched.sat_code && errors.sat_code) ||
                      (isError && validationErrors.findIndex((e) => e.field === "sat_code") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="sat_code"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={6}>
                  <Label for="unit_measurement">Unidad de Medida</Label>
                  <Select
                    name="unit_measurement"
                    id="unit_measurement"
                    className={
                      (touched.unit_measurement && errors.unit_measurement) ||
                      (isError && validationErrors.findIndex((e) => e.field === "unit_measurement") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.unit_measurement}
                    isDisabled={disabledForm}
                    onChange={(val) => {
                      setFieldValue("unit_measurement", val)
                    }}
                    onBlur={handleBlur}
                    onInputChange={setInputSelect}
                    isLoading={isLoading}
                    options={unitMeasurements?.data?.map((row) => ({
                      ...row,
                      label: `${row.key} - ${row.name}`,
                      value: row.id,
                    }))}
                    noOptionsMessage={() => "0 Unidades de Medida"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Unidad de medida"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="unit_measurement"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <Label className="form-label">
                    Costo <RequiredIcon />
                  </Label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      autoComplete="off"
                      className="form-control"
                      name="cost"
                      onBlur={(e) => {
                        const inputValue = e.target.value.replace(/,/g, "")
                        const formattedValue = currencyFormatter({ value: inputValue })
                        // Actualizar el valor formateado en el estado del formulario
                        setFieldValue("cost", formattedValue)
                        handleBlur(e)
                      }}
                      onChange={(e) => {
                        setFieldValue("cost", e.target.value)
                      }}
                      type="text"
                      value={values.cost}
                      invalid={
                        !!(touched.cost && errors.cost) ||
                        (isError && validationErrors.findIndex((e) => e.field === "cost") >= 0)
                      }
                    />
                  </InputGroup>
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="cost"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={6}>
                  <Label for="providers">Proveedores</Label>
                  <Select
                    name="providers"
                    id="providers"
                    className={
                      (touched.providers && errors.providers) ||
                      (isError && validationErrors.findIndex((e) => e.field === "providers") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isMulti
                    value={values.providers}
                    isDisabled={disabledForm}
                    onChange={(val) => {
                      setFieldValue("providers", val)
                    }}
                    onBlur={handleBlur}
                    onInputChange={setInputSelect}
                    isLoading={isLoading}
                    options={providers?.data?.map((element) => ({
                      value: element.id,
                      id: element.id,
                      label: element.name,
                    }))}
                    noOptionsMessage={() => "0 proveedores"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Proveedores"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="providers"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="taxes">Impuestos</Label>
                  <Select
                    name="taxes"
                    id="taxes"
                    className={
                      (touched.taxes && errors.taxes) ||
                      (isError && validationErrors.findIndex((e) => e.field === "taxes") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isMulti
                    value={values.taxes}
                    isDisabled={disabledForm}
                    onChange={(val) => {
                      setFieldValue("taxes", val)
                    }}
                    onBlur={handleBlur}
                    onInputChange={setInputSelect}
                    isLoading={isLoading}
                    options={taxes?.data?.map((element) => ({
                      value: element.id,
                      id: element.id,
                      label: `${element.key}`,
                    }))}
                    noOptionsMessage={() => "0 impuestos"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Impuestos"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="taxes"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <hr />
              <Row className="mb-3">
                <Col>
                  <Row>
                    <Col>
                      <h5>Lista de precios</h5>
                    </Col>
                  </Row>

                  {data?.id ? (
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Precio</th>
                          <th>Utilidad</th>
                          <th id={"tooltipQuantity"}>Cantidad</th>
                          <Tooltip
                            hideArrow={true}
                            innerClassName="mb-1"
                            style={{ backgroundColor: "#74788d" }}
                            placement="top"
                            isOpen={tooltipOpenQuantity}
                            target={"tooltipQuantity"}
                            toggle={toggleTooltipQuantity}
                          >
                            Cantidad mínima para aplicar la regla de descuento por volumen
                          </Tooltip>
                        </tr>
                      </thead>
                      <tbody>
                        {updatedData.map((price, index) =>
                          renderListProductItem(price, index, errors, touched, setFieldValue, values, handleBlur)
                        )}
                      </tbody>
                    </Table>
                  ) : (
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Precio</th>
                          <th>Utilidad</th>
                          <th id={"tooltipQuantity"}>Cantidad</th>
                          <Tooltip
                            hideArrow={true}
                            innerClassName="mb-1"
                            style={{ backgroundColor: "#74788d" }}
                            placement="top"
                            isOpen={tooltipOpenQuantity}
                            target={"tooltipQuantity"}
                            toggle={toggleTooltipQuantity}
                          >
                            Cantidad mínima para aplicar la regla de descuento por volumen
                          </Tooltip>
                        </tr>
                      </thead>
                      <tbody>
                        {activePrices.map((price, index) =>
                          renderListProductItemCreate(price, index, errors, touched, setFieldValue, values, handleBlur)
                        )}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>

              <hr />

              <div className="mt-3 text-end">
                {!isRetrivePage && (
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Cerrar
                  </Button>
                )}
                {!disabledForm && (
                  <Button color="success" type="submit" disabled={isLoading}>
                    <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

ProductsForm.defaultProps = {
  data: null,
  isRetrivePage: false,
  toggle: () => {},
  disabledForm: false,
}

ProductsForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  refetchData: propTypes.func,
  submit: propTypes.func,
  toggle: propTypes.func,
  prices: propTypes.object,
}

export default ProductsForm
