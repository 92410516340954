import propTypes from "prop-types"
import { useEffect, useCallback } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"

import { ImportForm } from "components"
import { masiveImportProducts } from "services/products"
import { useDownloadTemplate } from "hooks/use-download-template"

const ImportModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const downloadTemplate = useCallback(() => {
    useDownloadTemplate("/products/download_template")
  }, [])

  const { data, error, isError, isLoading, isSuccess, mutateAsync } = useMutation(masiveImportProducts)

  const submit = async (data) => {
    await mutateAsync(data)
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess(
        `Importación realizada satisfactoriamente <br/> <br/> Productos importados: ${
          data?.created?.length + data?.updated?.length
        } <br/> Productos no importados: ${data?.errors?.length} <br/> <br/>
        <a href="/logs-importation/${data?.log_id}">Ver más</a>
        `
      )
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Importar Productos
        {console.log(data)}
      </ModalHeader>
      <ModalBody>
        <div>
          <ImportForm
            submit={submit}
            isError={isError}
            error={error}
            isLoading={isLoading}
            toggle={toggle}
            downloadTemplate={downloadTemplate}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

ImportModalForm.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  setShowAlertSuccess: propTypes.func.isRequired,
  setMsgSuccess: propTypes.func.isRequired,
}

export default ImportModalForm
