import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery } from "@tanstack/react-query"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import CheckboxTree from "react-checkbox-tree"
import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllPermissions } from "services/Permissions"
import schema from "./schema"


const UserForm = ({ isCreate, submit, data, toggle, isLoadingSubmit, isError, error }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [typePermissions, setTypePermissions] = useState(data?.user_permissions?.map((e) => e.id) ?? [])
  const [expandedPermissions, setExpandedPermissions] = useState([])
  const [nodesPermissions, setNodesPermissions] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [openAccordion, setOpenAccordion] = useState("");

  const rolOptions = [
    { value: "A", label: "Administrador" },
    { value: "S", label: "Vendedor" },
    { value: "C", label: "Cajero" },
    { value: "E", label: "Supervisor" },
  ]
  const sellerPermissions = [
    "21", "24",   // Permissions for "cliente"
    "60",         // Permissions for "facturas de compra"
    "100",         // Permissions for "inventarios"
    "88",         // Permissions for "marca"
    "32",         // Permissions for "precio"
    "36",         // Permissions for "producto"
    "48",        // Permissions for "proveedor"
    "84",         // Permissions for "sucursal"
    "113", "114", "116", "117",  // Permissions for "tickets"
    "52",        // Permissions for "usuarios"
  ]
  const adminPermissions = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
    "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
    "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
    "41", "42", "43", "44", "45", "46", "47", "48", "49", "50",
    "51", "52", "53", "54", "55", "56", "57", "58", "59", "60",
    "61", "62", "63", "64", "65", "66", "67", "68", "69", "70",
    "71", "72", "73", "74", "75", "76", "77", "78", "79", "80",
    "81", "82", "83", "84", "85", "86", "87", "88", "89", "90",
    "91", "92", "93", "94", "95", "96", "97", "98", "99", "100",
    "101", "102", "103", "104", "105", "106", "107", "108", "109",
    "110", "111", "112", "113", "114", "115", "116", "117", "118",
    "119", "120", "121", "122", "123", "124", "125", "126", "127",
    "128", "128", "129", "130", "131", "132", "133", "134", "135", 
    "136", "137", "138", "139", "140", "141", "142", "143", "144", 
    "145", "146", "147", "148", "149", "150", "151", "152", "153", 
    "154", "155", "156", "157", "158", "159", "160", "161", "162", 
    "163", "164", "165", "166", "167", "168", "169", "170", "171", 
    "172", "173", "174", "175", "176", "177", "178", "179", "180", 
    "181", "182"
  ]
  const cashierPermissions = [
    "21", "24",   // Permissions for "cliente"
    "57", "60",   // Permissions for "facturas de compra"
    "100",         // Permissions for "inventarios"
    "44",         // Permissions for "marca"
    "32",         // Permissions for "precio"
    "36",         // Permissions for "producto"
    "48",        // Permissions for "proveedor"
    "84",         // Permissions for "sucursal"
    "113", "114", "116", "117",  // Permissions for "tickets"
    "52",        // Permissions for "usuarios"
  ]
  const supervisorPermissions = [
    "85", "86", "88",                   // Permissions for "almacen"
    "21", "22", "23", "24",             // Permissions for "cliente"
    "122", "125",                       // Permissions for "cortes de caja"
    "57", "58", "59", "60",             // Permissions for "facturas de compra"
    "112",                               // Permissions for "informacion del ticket"
    "97", "98", "99", "100",             // Permissions for "inventarios"
    "41", "42", "43", "44",             // Permissions for "marca"
    "29", "30", "31", "32",             // Permissions for "precio"
    "33", "34", "35", "36",             // Permissions for "producto"
    "45", "46", "47", "48",         // Permissions for "proveedor"
    "84",                               // Permissions for "sucursal"
    "113", "114", "115", "116", "117",  // Permissions for "tickets"
    "49", "50", "51", "52",         // Permissions for "usuarios"
  ]
  const dataForm = data ? { ...data } : {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    user_type: "S",
    user_permissions: sellerPermissions,
  }
  dataForm.user_type = rolOptions.find(rol => rol.value === dataForm.user_type)

  const permissions = useQuery(["permissions"], () => getAllPermissions())
  const isLoading = permissions.isLoading

  useEffect(() => {
    handleType()
  }, []);

  useEffect(() => {
    if (data?.user_permissions) {
      setTypePermissions(
        data?.user_permissions?.map((e) => {
          return e.id
        })
      )
    }
  }, [data])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  useEffect(() => {
    if (permissions.isSuccess) {
      setNodesPermissions(
        permissions?.data?.map((e) => {
          return {
            value: `Id - ${e.id}`,
            label: e.model,
            children: e.permissions?.map((e) => {
              return {
                value: e.id,
                label: e.name,
              }
            }),
          }
        })
      )
    }
  }, [permissions.isSuccess])

  const renderLoading = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoading} size={2} />
      </div>
    )
  }

  const renderErrorMessage = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={permissions.isError}>
          {permissions?.error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  const handleType = () => {
    setTypePermissions(sellerPermissions)
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setTypePermissions([])
      setSelectAll(!selectAll)
      return []
    } else {
      const allSelecteArray = permissions.data
        ?.map((e) => {
          return e.permissions?.map((e) => e.id)
        })
        .flat()
      setTypePermissions(allSelecteArray)
      setSelectAll(!selectAll)
      return allSelecteArray
    }
  }

  const toggleAccordion = (id) => {
    if (openAccordion === id) {
      setOpenAccordion("");
    } else {
      setOpenAccordion(id);
    }
  };

  return (
    <>
      {isLoading && (renderLoading())}
      {permissions.isError && (renderErrorMessage())}
      {permissions.data && (
        <Formik
          enableReinitialize
          initialValues={dataForm}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Nombre <RequiredIcon />
                  </Label>
                  <Input
                    name="first_name"
                    autoComplete="off"
                    className="form-control"
                    type="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    invalid={
                      !!(touched.first_name && errors.first_name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "first_name") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="first_name"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Apellido <RequiredIcon />
                  </Label>
                  <Input
                    name="last_name"
                    autoComplete="off"
                    className="form-control"
                    type="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    invalid={
                      !!(touched.last_name && errors.last_name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "last_name") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="last_name"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Email <RequiredIcon />
                  </Label>
                  <Input
                    name="email"
                    autoComplete="off"
                    className="form-control"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={
                      !!(touched.email && errors.email) ||
                      (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="email"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
                {
                  isCreate && (
                    <Col>
                      <Label className="form-label">
                        Contraseña <RequiredIcon />
                      </Label>
                      <Input
                        name="password"
                        autoComplete="off"
                        className="form-control"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        invalid={
                          !!(touched.password && errors.password) ||
                          (isError && validationErrors.findIndex((e) => e.field === "password") >= 0)
                        }
                      />
                      <ErrorsText
                        formName="password"
                        touched={touched}
                        errorsForm={errors}
                        isErrorBack={isError}
                        errorsBack={error}
                      />
                    </Col>
                  )
                }
              </Row>
              <Label className="form-label">
                Rol <RequiredIcon />
              </Label>
              <Select
                id="rol"
                name="rol"
                className={
                  (touched.type && errors.type) ||
                    (isError && validationErrors.findIndex((e) => e.field === "rol") >= 0)
                    ? "is-invalid"
                    : undefined
                }
                isClearable
                value={values.user_type}
                onChange={(event) => {
                  setFieldValue("user_type", event);
                  if (event.value === "S") {
                    setTypePermissions(sellerPermissions)
                    setFieldValue("user_permissions", sellerPermissions)
                  }
                  if (event.value === "A") {
                    setTypePermissions(adminPermissions)
                    setFieldValue("user_permissions", adminPermissions)
                  }
                  if (event.value === "C") {
                    setTypePermissions(cashierPermissions)
                    setFieldValue("user_permissions", cashierPermissions)
                  }
                  if (event.value === "E") {
                    setTypePermissions(supervisorPermissions)
                    setFieldValue("user_permissions", supervisorPermissions)
                  }
                }}
                onBlur={() => {
                  touched.type = true;
                  setTouched(touched);
                }}
                isLoading={rolOptions.isLoading}
                options={rolOptions}
                noOptionsMessage={() => "0 opciones"}
                loadingMessage={() => "Cargando..."}
                placeholder="Rol"
              />
              <hr />
              <Accordion open={openAccordion} toggle={toggleAccordion}>
                <AccordionItem>
                  <AccordionHeader targetId="1">Permisos</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Label check className="flo">
                              <Input
                                type="checkbox"
                                value={selectAll}
                                onChange={() => {
                                  setFieldValue("user_permissions", handleSelectAll())
                                }}
                              />{" "}
                              Seleccionar todos
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <CheckboxTree
                              id="user_permissions"
                              name="user_permissions"
                              iconsClass="fa5"
                              icons={{
                                leaf: <i className="fa fa-key" />,
                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                              }}
                              showExpandAll={true}
                              nodes={nodesPermissions}
                              checked={typePermissions}
                              expanded={expandedPermissions}
                              onCheck={(typePermissions) => {
                                setFieldValue("user_permissions", typePermissions)
                                setTypePermissions(typePermissions)
                              }}
                              onExpand={(expandedPermissions) =>
                                setExpandedPermissions(expandedPermissions)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <Row style={{ marginTop: 25 }}>
                <Col className="d-flex justify-content-end">
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoadingSubmit}>
                    <i className="fa fa-times" /> Cerrar
                  </Button>
                  <Button color="success" type="submit" disabled={isLoadingSubmit}>
                    <Loading isLoading={isLoadingSubmit} /> <i className="fa fa-save" /> Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}


UserForm.defaultProps = {
  data: null,
}


UserForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.any,
  isLoadingSubmit: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
}


export default UserForm
